import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import SectionLayout from "../layout/sectionLayout";
import * as style from "../../../styles/headerCarusell.module.scss"
import PageHeaderLeftItem from "./page-header-left-item";
import {getSrc} from "gatsby-plugin-image";

// look: https://react-bootstrap.github.io/components/carousel/

const CarouselItem = ({header}) => {

  const imageUrl = getSrc(header.titlePicture.localFile)
  const backgroundPictureStyle = {
    backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8) , rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 0.0)), url('${imageUrl}')`,
  }

  return (
    <>
      <div className={style.background} style={backgroundPictureStyle}>
      </div>

      <Carousel.Caption className="custom-carousel-caption" >
        <div className={style.item}>
          <PageHeaderLeftItem header={header} hideHeaderFromSeo />
        </div>
      </Carousel.Caption>

    </>
  )
}

const ControlledCarousel = ({headers}) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <SectionLayout>
        <Carousel
          className={style.setHeight}
          activeIndex={index}
          onSelect={handleSelect}
          interval={10000}
        >

        {/*Slides  */}
        {headers.map((header,index) => (
          <Carousel.Item className={style.setHeight} key={index}>
            <CarouselItem header={header} />
          </Carousel.Item>
        ))}

        </Carousel>
      </SectionLayout>
    </>
  );
};

export default ControlledCarousel;