import React from "react"
import SubblockWrapper from "./subblock-wrapper";

const SubblockEmpty = () => {
  return (
    <>
      {/* hidden on small screens */}
      <SubblockWrapper noPadding>
      </SubblockWrapper>
    </>
  )
}

export default SubblockEmpty
