import React from 'react';
import PageHeaderWithCarousel from "../page-header/page-header-with-carousel";

// create Button für header Component
const SubblockPageHeaderWithCarousel = ({content}) => {
  return (
    <>
      <PageHeaderWithCarousel headers={content} />
    </>
  );
};

export default SubblockPageHeaderWithCarousel;