import * as React from "react"

import Layout from "../components/common/layout/layout"

import Seo from "../components/seo";
import ProductCarusell from "../components/common/productCarusell/productCarusell";
import {graphql} from "gatsby";
import SubblockPicture from "../components/common/subblock/subblock-picture";
import SubblockRichText from "../components/common/subblock/subblock-rich-text";
import SubblockHeadlineWithSubtitle from "../components/common/subblock/subblock-headline-with-subtilte";
import SectionLayout from "../components/common/layout/sectionLayout";
import SubblockList from "../components/common/subblock/subblock-list";
import SubblockPictures from "../components/common/subblock/subblock-pictures";
import SubblockEmpty from "../components/common/subblock/subblock-empty";
import SubblockPageHeaderWithCarousel from "../components/common/subblock/subblock-page-header-with-carousel";

const HomePage = ({data}) => {

  // const headers = data.strapiHome.header
  const title = data.strapiHome.title
  const subTitle = data.strapiHome.subTitle
  const pictureLeft = data.strapiHome.pictureLeft
  const picturesRight = data.strapiHome.picturesRight
  const textRight = data.strapiHome.textRight
  const listTitle = data.strapiHome.listTitle
  const list = data.strapiHome.list
  const pictureRight = data.strapiHome.pictureRight
  const header = data.strapiHome.header

  return (
  <>
    <Seo title="Erfahrene Hände bei sensiblen Themen" />
    <Layout>
      {/*<HeaderCarusell/>*/}
      <SubblockPageHeaderWithCarousel content={header} />
      <SectionLayout>

        <SubblockHeadlineWithSubtitle title={title} subTitle={subTitle} textCenter setAsH1 />

        <SubblockPicture content={pictureLeft} />
        <SubblockRichText content={textRight} position="right" />
        <SubblockEmpty />
        <SubblockPictures content={picturesRight} />

        <ProductCarusell header="BELIEBTE PRODUKTE" productCarouselCollectionTitle="Highlights" />

        <SubblockList listTitle={listTitle} list={list} />
        <SubblockPicture content={pictureRight} />
      </SectionLayout>
    </Layout>
  </>
)}

export default HomePage

export const pageQuery = graphql`
query strapiHomeQuery {
  strapiHome {
    header {
      ...fragmentHeader
    }
    title
    subTitle
    pictureLeft {
      id
      mime
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    textRight {
      __typename
      data {
        id
        childMarkdownRemark {
          html
        }
      }
    }
    picturesRight {
      id
      mime
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    listTitle
    list {
      title
      subTitle
    }
    pictureRight {
      id
      mime
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}
`