import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SubblockWrapper from "./subblock-wrapper";
import {Col, Row} from "react-bootstrap";

// pictures halfscreen
const SubblockPictures = ({ content: pictures}) => {

  const colums = [12,12,6,4,3,2]
  const pictureColum = pictures.length <= 5 ? colums[12] : 1;

  return (
    <>
      <SubblockWrapper noPadding>
        <Row className="justify-content-md-center align-items-center m-0">
          {pictures.map( (picture)=>(
            <Col xs={pictureColum} className="d-flex justify-content-center p-0" key={picture.id} >
              <GatsbyImage
                image={getImage(picture.localFile)}
                alt={picture.alternativeText}
                imgStyle={{objectFit: "cover", width: "100%", height: "100%"}}
              />
            </Col>
          ))}
        </Row>
      </SubblockWrapper>
    </>
  )
}

export default SubblockPictures
